import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { Link } from "react-router-dom";
import axios from "../api/axiosapi";
import blank from "../../assets/blank.jpg";
import { BsFillPencilFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { WithContext as ReactTags } from 'react-tag-input';

import { Alert, Stack } from "@mui/material";

const EditDistributors = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/distributors");
  };

  const { data } = useContext(StateContext);
  console.log(data);

  const { id } = useParams();
  console.log(id);

  const [updatedValue, setUpdatedValue] = useState(data);
  const [accountT, setAccountT] = useState([]);

  function fetchData() {
    axios
      .get(`https://api.orbitmart.co.in/api/v1/auth/byDistributor/${id}`)
      .then((res) => {
        console.log(res.data);
        setUpdatedValue(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log(updatedValue);

  const [alert, setAlert] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedValue({ ...updatedValue, [name]: value });
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  const [formError, setFormError] = useState(false)

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  console.log(updatedValue.pincode, updatedValue)

  const [error, setError] = useState(false);

  async function handleSubmit(e) {
    const editId = data._id;
    const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = `https://api.orbitmart.co.in/api/v1/auth/distributor/${editId}`;
    const formData = new FormData();
    if(updatedValue.firstName.length === 0 || updatedValue.lastName.length === 0 || updatedValue.firmName.length === 0 || updatedValue.country.length === 0 || updatedValue.address.length === 0 || updatedValue.state.length === 0 || updatedValue.city.length === 0 || updatedValue.district.length === 0 || updatedValue.pincode.length === 0 || updatedValue.addharCard.length === 0 || updatedValue.panCard.length === 0 || updatedValue.mobile.length === 0 || updatedValue.email.length === 0 || updatedValue.bankName.length === 0 || updatedValue.accountNo.length === 0 || updatedValue.branchName.length === 0 || updatedValue.ifscCode.length === 0){
setFormError(true)

setTimeout(() => {
              setFormError(false)
            }, 3000);
    throw new Error('Please fill all the details')
    }
    formData.append("Image", selectedImages[0]);
    axios
      .post(uploadImage, formData)
      .then((res) => {
        debugger;
        console.log(res);
        const newimg = res.data.files;
        const coverPhoto = newimg[0];
        const finalData = {
          firstName: updatedValue.firstName,
          lastName: updatedValue.lastName,
          // firmName: updatedValue.firmName,
          country: updatedValue.country,
          address: updatedValue.address,
          state: updatedValue.state,
          city: updatedValue.city,
          district: updatedValue.district,
          pincode: updatedValue.pincode,
          // subPincode: updatedValue.subPincode,
          // addharCard: updatedValue.addharCard,
          // panCard: updatedValue.panCard,
          mobile: updatedValue.mobile,
          email: updatedValue.email,
          bankName: updatedValue.bankName,
          bankAccountType: updatedValue.bankAccountType,
          accountNo: updatedValue.accountNo,
          branchName: updatedValue.branchName,
          ifscCode: updatedValue.ifscCode,
          avatar: coverPhoto,
        };
        console.log(finalData);

        e.preventDefault();
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalData),
        };
        fetch(uploadData, requestOptions)
          .then(async (response) => {
            debugger;
            const isJson = response.headers
              .get("content-type")
              ?.includes("application/json");
            const data = isJson && (await response.json());
            console.log(data);
            setAlert(true);
            setTimeout(() => {
             !formError && mainPage();
            }, 1000);
          })
          .catch((err) => {
            setError(true);
            console.log(err);
            setTimeout(() => {
              mainPage();
            }, 1000);
          });
      })
      .catch((err) => {
        setError(true);
        console.log(err);
        setTimeout(() => {
          mainPage();
        }, 1000);
      });
  }

  // async function handleSubmit(e) {
  //   const editId = data._id;
  //   e.preventDefault();
  //   debugger;
  //   try {
  //     const res = await fetch(
  //       `https://api.orbitmart.co.in/api/v1/auth/distributor/${editId}`,
  //       {
  //         method: "put",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-token": "token-value",
  //         },
  //         body: JSON.stringify(updatedValue),
  //       }
  //     );
  //     if (!res.ok) {
  //       const message = `An error has occured: ${res.status} - ${res.statusText}`;
  //       throw new Error(message);
  //     }
  //     const data = await res.json();
  //     const result = {
  //       status: res.status + "-" + res.statusText,
  //       headers: { "Content-Type": res.headers.get("Content-Type") },
  //       data: data,
  //     };
  //     console.log(result);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  //   setAlert(true);
  //   setTimeout(() => {
  //     mainPage();
  //   }, 1000);
  // }




  
  const handleDelete = (i) => {
    const newTags = updatedValue.subPincode.slice(0);
    newTags.splice(i, 1);
    setUpdatedValue({ ...updatedValue, subPincode: newTags });
  };

  const handleAddition = (tag) => {
    const newTags = [...updatedValue.subPincode, tag.text];
    setUpdatedValue({ ...updatedValue, subPincode: newTags });
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const NUMBER = 6;
  const MAXTAGS = 3;

  return (
    <div>
      <div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">
        {alert ? (
          <Stack
            sx={{ width: "30%" }}
            style={{
              position: "fixed",
              left: "22%",
              bottom: "1%",
              width: "30%",
            }}
            spacing={2}
          >
            <Alert variant="filled" severity="success">
              Data Updated Successfully
            </Alert>
          </Stack>
        ) : null}
        {error ? (
          <Stack
            sx={{ width: "30%" }}
            style={{
              position: "fixed",
              left: "22%",
              bottom: "1%",
              width: "30%",
            }}
            spacing={2}
          >
            <Alert variant="filled" severity="error">
              Sorry, Data Cannot be Updated at this moment. Please try Again!
            </Alert>
          </Stack>
        ) : null}
        {formError ? (
          <Stack
            sx={{ width: "30%" }}
            style={{
              position: "fixed",
              left: "22%",
              bottom: "1%",
              width: "30%",
            }}
            spacing={2}
          >
            <Alert variant="filled" severity="error">
              Please fill all the data!
            </Alert>
          </Stack>
        ) : null}
        <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
          Edit Distributor
        </h4>
        <div class="flex space-x-2 justify-end">
          <button
            style={{ width: "8%" }}
            type="button"
            class="backButton"
            onClick={() => mainPage()}
          >
            Back
          </button>
        </div>
        <form>
          <div class="grid xl:grid-cols-3  m:gap-2">
            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>First Name</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="firstName"
                name="firstName"
                value={updatedValue.firstName}
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Last Name</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="lastName"
                name="lastName"
                value={updatedValue.lastName}
                onChange={handleChange}
              />
            </div>
            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Firm Name</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="firmName"
                name="firmName"
                value={updatedValue.firmName}
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Email</label>
              <input
                type="email"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="email"
                name="email"
                value={updatedValue.email}
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Mobile No</label>
              <input
                type="number"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="mobile"
                value={updatedValue.mobile}
                // onWheel={(e) => e.target.blur()}
                disabled
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Address</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="address"
                name="address"
                value={updatedValue.address}
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Pin Code</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="pincode"
                name="pincode"
                value={updatedValue.pincode}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 6);
                }}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>City</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="city"
                value={updatedValue.city}
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>District</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="district"
                value={updatedValue.district}
                onChange={handleChange}
              />
            </div>

            {/* <div class="relative z-0 p-2 w-full mb-6 group">
              <label
                for="gender"
                class="form-label inline-block mb-2 text-gray-700"
              >
                Gender
              </label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="gender"
                name="gender"
                value={updatedValue.gender}
                onChange={handleChange}
              />
            </div> */}

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>State</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="state"
                name="state"
                value={updatedValue.state}
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Gender</label>
              <select
                class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                name="gender"
                value={updatedValue.gender}
                onChange={handleChange}
              >
                <option>-- Select Type --</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </select>
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Country</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="country"
                value={updatedValue.country}
                // disabled
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Aadhaar Card</label>
              <input
                type="number"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="addharCard"
                value={updatedValue.addharCard}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 12);
                }}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Pan Card</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="panCard"
                value={updatedValue.panCard}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 10);
                }}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Bank Name</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="bankName"
                value={updatedValue.bankName}
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Account Type</label>
              <select
                class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                name="bankAccountType"
                value={updatedValue.bankAccountType}
                onChange={handleChange}
              >
                <option value="null">----Select Value----</option>
                <option value="SAVING">SAVING</option>
                <option value="CURRENT">CURRENT</option>
              </select>
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Account No</label>
              <input
                type="number"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="accountNo"
                value={updatedValue.accountNo}
                onChange={handleChange}
                onWheel={(e) => e.target.blur()}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Branch Name</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="branchName"
                value={updatedValue.branchName}
                onChange={handleChange}
              />
            </div>

            <div class="relative z-0 p-2 w-full mb-6 group">
              <label>Ifsc Code</label>
              <input
                type="text"
                class="form-control
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                name="ifscCode"
                value={updatedValue.ifscCode}
                onChange={handleChange}
              />
            </div>

            {console.log(updatedValue.subPincode)}

            <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Additional Pin Code</label>
            <ReactTags
			classNames={{
				tags: 'tagsClass',
				tagInput: 'tagInputClass',
				tagInputField: 'tagInputFieldClass',
				selected: 'selectedClass',
				tag: 'tagClass',
				remove: 'removeClass',
				suggestions: 'suggestionsClass',
				activeSuggestion: 'activeSuggestionClass',
				editTagInput: 'editTagInputClass',
				editTagInputField: 'editTagInputField',
				clearAll: 'clearAllClass',
			  }}
              tags={updatedValue?.subPincode?.map(pin => ({ id: pin, text: pin }))}
			//   maxTags= {MAXTAGS}
            //   suggestions={suggestions}
            //   delimiters={delimiters}
			placeholder= "Add Pin"
			inline
			// inputFieldPosition="inline"
			maxLength = {NUMBER}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
            //   handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              onChange={handleChange}
            //   autocomplete
            />
          </div>

            <div>
              <label className="ml-3">Image</label>
              <div class="relative mt-1 flex justify-center w-40">
                <div class=" absolute right-0 top-0 z-10 flex">
                  <label
                    for="file-upload"
                    class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                  >
                    <BsFillPencilFill />
                    <input
                      id="file-upload"
                      name="file-upload"
                      disabled={selectedImages.length == 1}
                      accept="image/*"
                      type="file"
                      onChange={onSelectFile}
                      class="sr-only"
                    />
                  </label>
                </div>
                <div class="relative z-0 p-2 w-full mb-6 group">
                  {selectedImages.length > 0 ? (
                    selectedImages.map((item, index) => {
                      return (
                        <div style={{ display: "inline-block" }} key={item}>
                          <p className="block-icon">
                            <img src={URL.createObjectURL(item)} alt="img" />
                            <ImCross
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteHandler(item)}
                              className="icon-belowtag"
                            />
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <img src={updatedValue.avatar} alt="user-profile" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="flex space-x-2 justify-end mr-2">
          <button
            style={{ width: "8%" }}
            type="submit"
            onClick={handleSubmit}
            class="addButton"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditDistributors;
