import React,{useState, useEffect} from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import { AiOutlineFileSearch } from "react-icons/ai";
// import { itemsData, contextMenuItems, itemsGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import {
//   itemsGrid,
//   itemsData,
//   contextMenuItems,
//   gridItemAction,
//   gridTransactionStatus,
//   gridPaymentStatus
// } from "./OnlineTransactionConfig";
import "./style.css";
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import { Button } from "@material-ui/core";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
  },
  cell: {
    padding: "8px", 
    textAlign: "start",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "8px", 
    textAlign: "start",
  },
}));

const Franchise = () => {
  const selectionsettings = { persistSelection: true };
  const classes = useStyles();
  const editing = { allowDeleting: true };
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState([])

  const navigate = useNavigate();

  const handleContactData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://api.orbitmart.co.in/api/v1/applyForFranchise');
      setContact(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    handleContactData();
  }, []);


  console.log(contact, "itemsData")

  const filteredData = contact.filter((item) => {
    const customername = item.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
      const orderNo = item?.mobile.includes(searchQuery);

      return customername || orderNo;
  });

  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
     setCurrentPage(0);
   } else if (newPage === Math.ceil(filteredData?.length / pageSize) - 1) {
     setCurrentPage(Math.ceil(filteredData?.length / pageSize) - 1);
   } else {
     setCurrentPage(newPage);
   }
  };
  
   const handleChangeRowsPerPage = (event) => {
   setPageSize(parseInt(event.target.value, 5));
   setCurrentPage(0);
  };
  
   const CustomPaginationActions = (props) => {
    
    console.log('Custom', props)
    const { count, page, rowsPerPage, onPageChange } = props;
    // const handleFirstPageButtonClick = (event) => {
    //   setLoading(true);
    //   onPageChange(event, 0);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    // const handleLastPageButtonClick = (event) => {
    //   setLoading(true);
      // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  
    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

  const currentPageData = filteredData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );

  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };


  return (
    <div>
      <div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">

        {/* <Header  title="Online Transaction List" /> */}
   
        <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 0 20px 0",
          padding: "8px",
          borderRadius: "20px",
          background: "rgb(0, 60, 126)",
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
        Franchise Contact ({contact?.length || 0})
        </h1>
        <span
          className="flex space-x-2 m-4 justify-end"
          style={{ background: "white", borderRadius: "6px" }}
        >
          <input
            style={{
              padding: "4px 10px",
              outline: "none",
              borderRadius: "6px 0 0 6px",
              color: "black",
            }}
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <BiSearch
            style={{
              fontSize: "25px",
              color: "gray",
              cursor: "pointer",
              margin: "auto",
              paddingRight: "5px",
            }}
          />{" "}
        </span>
      </div>
      {/* {console.log(itemsData, "responseData safs")} */}
      <TableContainer className={currentPageData?.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Name</TableCell>
              <TableCell className={classes.cellRow}>Email</TableCell>
              <TableCell className={classes.cellRow}>Message</TableCell>
              <TableCell className={classes.cellRow}>Mobile</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {contact?.length > 0 ? (
              currentPageData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell className={classes.cell}>{`${item?.name} ₹`}</TableCell>
                  <TableCell className={classes.cell}>{item?.email}</TableCell>
                  <TableCell className={classes.cell}>{item?.message}</TableCell>
                  <TableCell className={classes.cell}>{item?.mobile}</TableCell>
                  
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {contact?.length > 0 ? (
          <TablePagination
            count={contact.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[2]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
      </TableContainer>


        {/* <GridComponent
          id="gridcomp"
          width="auto"
          dataSource={itemsData}
          allowPaging
          allowSorting
          allowExcelExport
          pageSettings={{ pageCount: 5 }}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
        >
          <ColumnsDirective>
            {itemsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Search,
              ContextMenu,
              Page,
              Selection,
              Toolbar,
              Edit,
              Sort,
              Filter,
              ExcelExport,
              PdfExport,
            ]}
          />
        </GridComponent> */}
      </div>
    </div>
  );
};
export default Franchise;
