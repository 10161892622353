import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../pages/view.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReactDOM from "react-dom";
import axios from "axios";
import ConfirmPopup from "./ConfirmPopup";
import RejectPopup from "./RejectPopup";
//icon
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import StateContext from "../../contexts/ContextProvider";
import { ToolbarStatus } from "@syncfusion/ej2-react-richtexteditor";
import { Data } from "@syncfusion/ej2-react-grids";
// import { showErrorMsg } from "../../components/helper/message";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// function createData(srno, name, qty, unit, amount) {
//   return { srno, name, qty, unit, amount };
// }

const rows = [
  createData(0, 0, "Pizza GardenDelight", 4, 500),
  createData(1, 1, "Veg Cheese Burger", 2, 80),


];


function priceRow(qty, unit) {
  return qty * unit;
}

function createData(id, srno, name, qty, unit) {
  const price = priceRow(qty, unit);
  return { id, srno, name, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

let invoiceSubtotal = subtotal(rows);

const handleChange = (e) => {
  createData(e.target.value);
  e.preventDefault();
};

const reducer = (state, action) => {
  switch (action.type) {
    case "increment":
      return state.map((substate) => {
        if (action.id.id === substate.id) {
          invoiceSubtotal = subtotal(state) + substate.unit;
          return {
            ...substate,
            qty: substate.qty + 1,
            price: (substate.qty + 1) * substate.unit,
          };
        } else {
          return substate;
        }
      });
    case "decrement":
      return state.map((substate) => {
        if (action.id.id === substate.id && substate.qty > 0) {
          invoiceSubtotal = subtotal(state) - substate.unit;
          return {
            ...substate,
            qty: substate.qty - 1,
            price: (substate.qty - 1) * substate.unit,
          };
        } else {
          return substate;
        }
      });
    default:
      return state;
  }
};

export default function ConfirmModal(props) {
//   const [quant, dispatch] = React.useReducer(reducer, rows);
  const [open, setOpen] = React.useState(false);
  const [confirmopen, setConfirmOpen] = React.useState(false);
  const [rejectOpen, setRejectOpen] = React.useState(false);
  const [date, setDate] = React.useState();
  const [ordernum, setOrdernum] = React.useState(
    props.props.orderNo.toUpperCase()
  );
const {setOrderData}=useContext(StateContext)
  //changes start here
  const [product,setProduct]=useState(props.props.productDetails)
// console.log(product)
//   const orizCom = product
//     ?.map((pr) => {
//       return parseInt(pr?.productId?.orbitMartCommission)||0;
//     })
//     .reduce((a, b) => a + b);

//  const discount=product.map((pr)=>{
//   let dis=parseInt(pr.productId.customerDiscount)||0
//   if(pr.productId.discountType=='FIXED')
//   {

//     return  dis
//   }
//   else{
//     return  (parseInt(pr?.productId?.itemPrice)*dis)/100
//   }
// }).reduce((a,b)=>a+b)


// const subtotal=props.props.totalPrice

// const gst=((subtotal*parseInt(product[0].productId.gst))/100)
//changes done
const [errMsg, setErrMsg] = useState(false);
const [time, setTime] = useState('');
const [msg, setMsg] = useState("");
var timeout;
const displayErrMsg=(setErrMsg, timeout)=> {
  setErrMsg(true);
  clearTimeout(timeout);
  timeout = setTimeout(function () {
    setErrMsg(false);
  },2000);
  return timeout;
}
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const HandleReject = () => setRejectOpen(true);
  // const API = "https://api.orbitmart.co.in/api/v1/order";
  const API = 'https://api.orbitmart.co.in/api/v1/auth/admin/order'


  const handleConfirm = async () => {
    if (date === "NaN-NaN-NaN") {
      setMsg(`Please select pickup date and time`);
      timeout = displayErrMsg(setErrMsg, timeout);
      setLoading(false);
    } else {
      const payload = {
        orderStatus: "PROCESSING",
        date: date,
        time: time,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-token": sessionStorage.getItem("token"),
        },
      };
      await axios
        .put(`${API}/${props.props._id}`, payload, config)
        .then(async (response) => {
          const SuccessRes = response.data.status == "SUCCESS";
          if (SuccessRes) {
            await axios

              .get(`https://api.orbitmart.co.in/api/v1/order`)
              .then((res) => {
                let data = res.data.data
                  // .filter(
                  //   (or) => or.distributorId == sessionStorage.getItem("id")
                  // )
                  // .reverse()
                  // .map((itemId) => {
                  //   itemId.grandTotal = getAmount(itemId);
                  //   return itemId;
                  // });
                // setTDatas(data)

                setOrderData(data);
                handleClose();
                useNavigate('/orders')
              })
              .catch((err) => {
                console.log("orderdata error", err.message);
              });
            setConfirmOpen(true);
            setOpen(false);
          }
          console.log("confirm response", response);
        })
        .catch((error) => {
          console.log("confirm response error!", error);
        });
    }
  };
 


  // const getAmount=(itemId)=>{
  //   return ((itemId.totalPrice)-(itemId.productDetails.map((pr)=>{

  //    if(pr?.productId?.discountType=='FIXED')
  //    {
  //      return  parseInt(pr.productId.customerDiscount)
  //    }
  //    else{
  //      return  (parseInt(pr.productId?.itemPrice)*parseInt(pr.productId?.customerDiscount!==""?pr.productId?.customerDiscount:0))/100
  //    }
  //  }).reduce((a,b)=>a+b))+ (((itemId.totalPrice*parseInt(itemId.productDetails[0].productId?.gst))/100))+20+itemId?.productDetails
  //  ?.map((pr) => {
  //    return parseInt(pr?.productId?.orbitMartCommission);
  //  })
  //  .reduce((a, b) => a + b)).toFixed(0)
  //  }
  const getAmount = (itemId) => {
    const subtotal = itemId?.productDetails
      ?.map((pro) => {
        return pro.productId?.itemPrice * pro?.quantity;
      })
      .reduce((a, b) => a + b);
    const customerDiscount = itemId?.productDetails
      ?.map((pr) => {
        if (pr?.productId?.discountType == "FIXED") {
          return parseFloat(pr?.productId?.customerDiscount);
        } else {
          return (
            (parseFloat(pr?.productId?.itemPrice) *
              parseFloat(pr?.quantity) *
              parseFloat(pr?.productId?.customerDiscount)) /
            100
          );
        }
      })
      .reduce((a, b) => a + b);
    const GstLogic =
      ((subtotal - customerDiscount) *
        parseFloat(itemId?.productDetails?.[0].productId?.gst)) /
      100;
    const DeliveryAmount = 0;
    const TotalAmount = (
      subtotal -
      customerDiscount +
      GstLogic +
      DeliveryAmount
    ).toFixed(0);
    return TotalAmount;
  };
  // =================date time start===============
  const handleDate = (e) => {
    const date = new Date(e);

  // Format date as DD-MM-YYYY
  const day = date.getDate().toString();
  const month = (date.getMonth() + 1).toString()
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  setDate(formattedDate);

  // Format time as 12-hour with AM/PM
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // const ampm = hours >= 12 ? 'PM' : 'AM';
  // const formattedHours = hours % 12 || 12; // Convert 0 hours to 12 for AM/PM
  const formattedTime = `${hours}:${minutes.toString()}:0`;
  setTime(formattedTime);
  };

  console.log(time, date, "time format and date format")
  const d1 = new Date(date);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }
  function formatTime(date) {
    var d = new Date(date),
      hour = "" + d.getUTCHours(),
      minute = "" + d.getUTCMinutes(),
      second = d.getUTCSeconds();

    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    return [hour, minute, second].join(":");
  }

  var dateresult = formatDate(date);
  var timeresult = formatTime(d1);
  // console.log("date",timeresult);
  // console.log("dateresult",dateresult);
  // =================date time end===============

  //UseState
  // const [quantity, setQuantity] = useState([]);

  // const handleIncrement = (qty) => {
  //   setQuantity((rows) =>
  //     rows.map((row) => {
  //       qty === row.qty ? { ...row, qty: row.qty + 1 } : row;
  //     })
  //   );
  // };

  // const handleDecrement = (qty) => {
  //   setQuantity((rows) =>
  //     rows.map((row) => {
  //       qty === row.qty ? { ...row, qty: row.qty - 1 } : row;
  //     })
  //   );
  // };

  // const handleDecrement = (card_id) => {
  //   createData((rows) =>
  //     rows.map((row) => {
  //       card_id === row.id ? { ...row, qty: row.qty - 1 } : row;
  //     })
  //   );
  // };

  // const handleIncrement = (card_id) => {
  //   createData((rows) =>
  //     rows.map((row) => {
  //       card_id === row.id ? { ...row, qty: row.qty + 1 } : row;
  //     })
  //   );
  // };

  // var ReactDOM = require("react-dom");

  

  // function changeHandle(e) {
  //   e.preventDefault();
  //   createData(e.target.value);
  // }
  const subtotal = props.props?.productDetails
  ?.map((pro) => {
    return pro.productId?.itemPrice * pro?.quantity;
  })
  .reduce((a, b) => a + b)||0;
  // console.log(subtotals, "subtotals")
// const subtotal  = 100

// const orbitMartCommissionLogic = props.props?.productDetails
//   ?.map((pr) => {
//     return parseInt(pr?.productId?.orbitMartCommission);
//   })
//   .reduce((a, b) => a + b)||0;

const orbitMartCommissionLogic = 200

const customerDiscount = props.props?.productDetails
  ?.map((pr) => {
    if (pr?.productId?.discountType == "FIXED") {
      return parseFloat(pr?.productId?.customerDiscount);
    } else {
      return (
        (parseFloat(pr?.productId?.itemPrice) *
          parseFloat(pr.quantity) *
          parseFloat(pr?.productId?.customerDiscount)) /
        100
      );
    }
  })
  .reduce((a, b) => a + b)||0;
// const customerDiscount = 300

// const GstLogic =
//   ((subtotal - customerDiscount) *
//     parseFloat(props.props?.productDetails?.[0].productId?.gst)) /
//   100||0;
const GstLogic = 0
  const DeliveryAmount = 0;
  // const TotalAmount = (
  //   subtotal -
  //   customerDiscount +
  //   GstLogic +
  //   DeliveryAmount
  // ).toFixed(0);
  const TotalAmount=props.props?.totalPrice
  return (
    <div>
       {errMsg && showErrorMsg(msg, errMsg)}
      <button
        style={{ color: "white", backgroundColor: "#8be78b", width: "83px"}}
        type="button"
        onClick={() => handleOpen()}
        class="px-2
      py-2
      bg-blue-600
      text-white
      font-small
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out"
      >
        Confirm
      </button>
      <ConfirmPopup open={confirmopen} setConfirmOpen={setConfirmOpen} />
      <RejectPopup
        open={rejectOpen}
        setRejectOpen={setRejectOpen}
        data={props}
        parentmodal={setOpen}
      />
      <Modal
        open={open}
        onChange={(e) => handleChange(e)}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        testId={createData}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div class="flex justify-end active">
            <div class="backIcon">
              <button onClick={handleClose}>
                <AiOutlineRollback />
              </button>
            </div>
          </div>
          <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
            Order Confirmation
          </h4>
          <form>
            <div className="grid xl:grid-cols-2  m:gap-2">
              <div class="relative z-0 p-2 w-full mb-6 group">
                <label
                  for="orderno"
                  class="form-label inline-block mb-2 text-gray-700"
                >
                  Order No
                </label>
                <input
                  type="text"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="orderno"
                  // placeholder={ordernum}
                  readonly
                  value={ordernum}
                />
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                <label
                  for="date"
                  class="form-label inline-block mb-2 text-gray-700"
                >
                  Pick Up Date & Time
                </label>
                <input
                  type="datetime-local"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="date"
                  placeholder="Select Data & Time"
                  min={new Date().toISOString().slice(0, 16)}
                  onChange={(e) => handleDate(e.target.value)}
                />
              </div>
            </div>
          </form>
          <TableContainer onChange={() => handleChange()} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="left">Unit Price</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
         
             
               {product.map((pr,index)=>(  <TableRow> 
                <TableCell component="th" scope="row">{index}</TableCell>
       
               <TableCell align="center">{pr?.productId?.itemName}</TableCell>
               <TableCell align="center">{pr?.quantity}</TableCell>
               <TableCell align="left">{pr?.productId?.itemPrice}</TableCell>
               <TableCell align="right">₹{parseInt(pr?.productId?.itemPrice)*parseInt(pr?.quantity)}</TableCell> </TableRow>))}
               

                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Subtotal
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "#03c9d7" }}
                    align="right"
                  >
                    ₹{subtotal}
                    {/* {invoiceSubtotal} */}
                    {/* {pr.totalPrice} */}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Discount
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "red" }}
                    align="right"
                  >
                     ₹{Math.ceil(customerDiscount)}
                    {/* {invoiceSubtotal} */}
                    {/* {pr.totalPrice} */}
                  </TableCell>
                </TableRow>
                
              
              
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Gst
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    align="right"
                  >
               ₹{GstLogic}
                    {/* {invoiceSubtotal} */}
                    {/* {pr.totalPrice} */}
                  </TableCell>
                </TableRow>
                <TableRow  >
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell  style={{ fontWeight:"bolder" }} align="left">
                    Delivery Charges
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    align="right"
                  >
                   ₹{DeliveryAmount}
                    {/* {invoiceSubtotal} */}
                    {/* {pr.totalPrice} */}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                   Grand Total
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", color: "#03c9d7" }}
                    align="right"
                  >
                   {/* {orizCom+20-discount+gst+subtotal} */}
                   ₹{TotalAmount}
                  
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ paddingTop: 15 }}></div>
          <div class="flex space-x-2 justify-end">
            {/* <button
              type="button"
              class="backButton"
              style={{ width: "12%" }}
              onClick={handleClose}
            >
              Back
            </button> */}
            <button
              type="button"
              class="addButton"
              style={{ width: "12%" }}
              onClick={handleConfirm}
            >
              Confirm
            </button>
            <button
              type="button"
              class="backButton"
              style={{ width: "12%" }}
              onClick={HandleReject}
            >
              Reject
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
