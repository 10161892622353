import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import StateContext from './contexts/ContextProvider';
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import {
	Admin,
	Calendar,
	Stacked,
	Pyramid,
	Customers,
	Kanban,
	Line,
	Area,
	Bar,
	Pie,
	Financial,
	ColorPicker,
	ColorMapping,
	Editor
} from './pages';
import './App.css';

// Authentication
import Auth from './pages/login/Login';
import Register from './pages/auth/Register';

//ContextPart

//MainPage
import ManageItem from './pages/manageItem/ManageItem';
import Users from './pages/users/Users';
import Employees from './pages/employee/Employees';
import Merchants from './pages/merchants/Merchants';
import Distributors from './pages/distributors/Distributors';
import Orders from './pages/orders/Orders';
import Invoice from './pages/invoice/Invoice';
import DeliveryRate from './pages/deliveryRate/DeliveryRate';
import MerchantType from './pages/merchantType/MerchantType';
import Banner from './pages/banner/Banner';
import SubClassification from './pages/subClassification/SubClassification';
import GroupCategory from './pages/groupCategory/GroupCategory';
import BrandName from './pages/brandName/BrandName';
import DomainType from './pages/domainType/DomainType';
import Classification from './pages/classification/Classification';
import Categorys from './pages/categorys/Categorys';
import UnitQuantity from './pages/unitQuantity/UnitQuantity';
import UnitPerQuantity from './pages/unitPerQuantity/UnitPerQuantity';
import Service from "./pages/Service/Service"
import AddService from './pages/Service/Addservice';
import Editservice from './pages/Service/Editservice';
import Contact from './pages/contacts/Contacts';


import { useStateContext } from './contexts/ContextProvider';

//AddPanels
import AddManageItem from './pages/manageItem/AddManageItem';
import AddEmployee from './pages/employee/AddEmployee';
import AddMerchant from './pages/merchants/AddMerchants';
import AddDistributors from './pages/distributors/AddDistributors';
// import AddVoucher from "./pages/voucher/AddVoucher";
import AddInvoice from './pages/invoice/AddInvoice';
import AddDelivery from './pages/deliveryRate/AddDelivery';
// import AddCategory from "./pages/category/AddCategory";
import AddBanner from './pages/banner/AddBanner';
import AddMerchantType from './pages/merchantType/AddMerchantType';
import AddGroupCategory from './pages/groupCategory/AddGroupCategory';
import AddBrandName from './pages/brandName/AddBrandName';
import AddSubClassification from './pages/subClassification/AddSubClassification';
import AddDomainType from './pages/domainType/AddDomainType';
import AddClassification from './pages/classification/AddClassification';
import AddCategorys from './pages/categorys/AddCategorys';
import AddUnitQuantity from './pages/unitQuantity/config/AddUnitQuantity';
import AddUnitPerQuantity from './pages/unitPerQuantity/config/AddUnitPerQuantity';


//ViewPabels

import ViewUser from './pages/users/ViewUser';
import ViewEmployee from './pages/employee/ViewEmployee';
import ViewMerchant from './pages/merchants/ViewMerchant';
import ViewDistributor from './pages/distributors/ViewDistributor';
import ViewOrder from './pages/orders/ViewOrder';
// import ViewCategory from "./pages/category/ViewCategory";

//Add Admin
import AddAdmin from './pages/addAdmin/AddAdmin';
import Register1 from './pages/register/Register1';

//EditPanels
import EditManageItem from './pages/manageItem/EditManageItem';
import EditOrder from './pages/orders/EditOrder';
// import EditCategory from "./pages/category/EditCategory";
import EditEmployee from './pages/employee/EditEmployee';
import EditMerchant from './pages/merchants/EditMerchant';
import EditMerchantType from './pages/merchantType/EditMerchantType';
import EditDistributors from './pages/distributors/EditDistributors';
import EditVoucher from './pages/voucher/EditVoucher';
import EditDelivery from './pages/deliveryRate/EditDelivery';
import EditCategories from './pages/categorys/EditCategories';
import EditClassification from './pages/classification/EditClassification';
import EditSubClassification from './pages/subClassification/EditSubClassification';
import EditUnitQuantity from './pages/unitQuantity/EditUnitQuantity';
import EditUnitPerQuantity from './pages/unitPerQuantity/EditUnitPerQuantity';
import EditGroupCategory from './pages/groupCategory/EditGroupCategory';
import { dataBinding } from '@syncfusion/ej2-react-kanban';
import PrivacyPolicy from './pages/PrivacyPolicy';

// import { onMessageListener } from "./firebaseInit";
// import Notifications from "./components/Notifications/Notifications";
// import ReactNotificationComponent from "./components/Notifications/ReactNotification";
import EditBanner from './pages/banner/EditBanner';
import EditProfile from './components/EditProfile/EditProfile';
import ViewNotification from './pages/notification/ViewNotification';
import { AddNotification } from './pages/notification/AddNotification';
import EditNotification from './pages/notification/EditNotification';
import Notification from './pages/notification/Notification';
import OrderDetails from './pages/users/OrderDetails';
import Franchise from './pages/franchise/Franchise';

// Uncaught FirebaseError: Messaging: This browser doesn't support the API's required to use the firebase SDK. (messaging/unsupported-browser).
const App = () => {
	const {
		setCurrentColor,
		setCurrentMode,
		currentMode,
		activeMenu,
		currentColor,
		themeSettings,
		setThemeSettings,
		isLoggedIn,
		auth,
		setAuth,
		loginData,
		setLoginData
	} = useStateContext();

	// console.log(`IsLoggedIn : ${isLoggedIn}`);

	const userLogin = () => {
		const newData = sessionStorage.getItem('admin_login_data');
		if (newData != null) {
			setAuth(true);
		}
	};

	useEffect(async () => {
		// debugger;
		userLogin();
		const currentThemeColor = localStorage.getItem('colorMode');
		const currentThemeMode = localStorage.getItem('themeMode');
		if (currentThemeColor && currentThemeMode) {
			setCurrentColor(currentThemeColor);
			setCurrentMode(currentThemeMode);
		}
	}, []);

	//LoginPart
	// const newData = localStorage.getItem("login_data");
	// console.log(newData);

	// useEffect(() => {
	//   userLogin();
	// }, [auth]);

	// useEffect(() => {
	//   debugger;
	//   const data = window.localStorage.getItem("MY_APP_STATE");
	//   console.log(data);
	//   if (data.messageCode == 200) {
	//     setAuth(true);
	//   }
	// }, []);

	// // // Firebase start
	// const [show, setShow] = useState(false);
	// const [notification, setNotification] = useState({ title: "", body: "" });

	// console.log(show, notification);

	// onMessageListener()
	//   .then((payload) => {
	//     setShow(true);
	//     setNotification({
	//       title: payload.notification.title,
	//       body: payload.notification.body,
	//     });
	//     console.log(payload);
	//     setTimeout(function(){ setShow(false) }, 3000);
	//   })
	//   .catch((err) => console.log("failed: ", err));

	// // //   Firebase end
	return (
		<div>
			{/* {show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )}
      <Notifications /> */}
			{!auth ? (
				<Register1 />
			) : (
				<Fragment>
					<div className={currentMode === 'Dark' ? 'dark' : ''}>
						<BrowserRouter>
							<div className="flex relative dark:bg-main-dark-bg">
								
								{activeMenu ? (
									<div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
										<Sidebar />
									</div>
								) : (
									<div className="w-0 dark:bg-secondary-dark-bg">
										<Sidebar />
									</div>
								)}
								<div
								style={{}}
									className={
										activeMenu ? (
											// 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
											'dark:bg-main-dark-bg bg-white min-h-screen md:ml-72 w-full ' 
										) : (
											'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
										)
									}>
									<div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar ">
										<Navbar />
									</div>
									<div>
										{themeSettings && <ThemeSettings />}

										<Routes>
											{/* dashboard  */}
											<Route path="/" element={<Admin />} />
											<Route path="/Admin" element={<Admin />} />
											<Route path="/addadmin" element={<AddAdmin />} />
											{/* authentication */}
											<Route path="/register" element={<Register />} />
											<Route path="/login" element={<Auth />} />
											{/* pages  */}
											<Route path="/regi" element={<Register1 />} />
											<Route path="/editprofile" element={<EditProfile />} />
											<Route path="/manageItem" element={<ManageItem />} />
											<Route path="/users" element={<Users />} />
											<Route path="/employees" element={<Employees />} />
											<Route path="/merchants" element={<Merchants />} />
											<Route path="/distributors" element={<Distributors />} />
											<Route path="/orders" element={<Orders />} />
											<Route path="/invoice" element={<Invoice />} />
											<Route path="/deliveryrate" element={<DeliveryRate />} />
											{/* <Route path="/category" element={<Category />} /> */}
											<Route path="/merchantType" element={<MerchantType />} />
											<Route path="/banner" element={<Banner />} />
											<Route path="/domainType" element={<DomainType />} />
											<Route path="/classification" element={<Classification />} />
											<Route path="/category" element={<Categorys />} />
											<Route path="/subClassification" element={<SubClassification />} />
											<Route path="/brandName" element={<BrandName />} />
											<Route path="/groupCategory" element={<GroupCategory />} />
											<Route path="/unitQuantity" element={<UnitQuantity />} />
											<Route path="/unit/Quantity" element={<UnitPerQuantity />} />
											<Route path="/Service" element={<Service />} />
											<Route path="/Addservice" element={<AddService />} />
											<Route path="/Editservice" element={<Editservice />} />
											<Route path="Contact" element={<Contact />} />
											<Route path="Franchise" element={<Franchise />} />

											{/* apps  */}
											{/* <Route path="/kanban" element={<Kanban />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/color-picker" element={<ColorPicker />} /> */}
											{/* AddPath  */}
											<Route path="/manageItem/add" element={<AddManageItem />} />
											<Route path="/employees/add" element={<AddEmployee />} />
											<Route path="/merchant/add" element={<AddMerchant />} />
											<Route path="/distributors/add" element={<AddDistributors />} />
											<Route path="/invoice/add" element={<AddInvoice />} />
											<Route path="/deliveryrate/add" element={<AddDelivery />} />
											{/* <Route path="/categor/add" element={<AddCategory />} /> */}
											<Route path="/merchantType/add" element={<AddMerchantType />} />
											<Route path="/banner/add" element={<AddBanner />} />
											<Route path="/banner/edit/:id" element={<EditBanner />} />
											<Route path="/domainType/add" element={<AddDomainType />} />
											<Route path="/categorys/add" element={<AddCategorys />} />
											<Route path="/classification/add" element={<AddClassification />} />
											<Route path="/subClassification/add" element={<AddSubClassification />} />
											<Route path="/brandName/add" element={<AddBrandName />} />
											<Route path="/groupCategory/add" element={<AddGroupCategory />} />
											<Route path="/unitQuantity/add" element={<AddUnitQuantity />} />
											<Route path="/unitPerQuantity/add" element={<AddUnitPerQuantity />} />

											{/* ViewPath  */}
											{/* <Route path="/manageItem/view" element={<ViewManageItem />} /> */}
											<Route path="/users/view" element={<ViewUser />} />
											<Route path="/users/view/OrderDetails" element={<OrderDetails />} />
											<Route path="/employees/view" element={<ViewEmployee />} />
											<Route path="/merchants/view/:id" element={<ViewMerchant />} />
											<Route path="/distributors/view/:id" element={<ViewDistributor />} />
											<Route path="/orders/view" element={<ViewOrder />} />
											{/* <Route
                        path="/categor/view/:id"
                        element={<ViewCategory />}
                      /> */}
											{/* EditPath  */}
											<Route path="/manageItem/edit" element={<EditManageItem />} />
											<Route path="/orders/edit" element={<EditOrder />} />
											<Route path="/employees/edit" element={<EditEmployee />} />
											<Route path="/merchants/edit" element={<EditMerchant />} />
											{/* <Route
                        path="/category/edit/:id"
                        element={<EditCategory />}
                      /> */}
											<Route path="/distributors/edit/:id" element={<EditDistributors />} />
											<Route path="/voucher/edit" element={<EditVoucher />} />
											<Route path="/deliveryrate/edit" element={<EditDelivery />} />
											<Route path="/merchantType/edit" element={<EditMerchantType />} />
											<Route path="/subClassification/edit" element={<EditSubClassification />} />
											<Route path="/categorys/edit" element={<EditCategories />} />
											<Route path="/classification/edit" element={<EditClassification />} />
											<Route path="/unitQuantity/edit/:id" element={<EditUnitQuantity />} />
											<Route path="/unit/Quantity/edit/:id" element={<EditUnitPerQuantity />} />
											<Route path="/groupCategory/edit" element={<EditGroupCategory />} />
											<Route path="/notification" element={<Notification />} />
											<Route path="/notification/add" element={<AddNotification />} />
											<Route path="/notification/edit" element={<EditNotification />} />
											<Route path="/notification/view" element={<ViewNotification />} />
											{/* <Route path="/policy" element={<PrivacyPolicy />} /> */}
											{/* charts  */}
											{/* <Route path="/line" element={<Line />} />
                <Route path="/area" element={<Area />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/financial" element={<Financial />} />
                <Route path="/color-mapping" element={<ColorMapping />} />
                <Route path="/pyramid" element={<Pyramid />} />
                <Route path="/stacked" element={<Stacked />} /> */}
										</Routes>
									</div>
									<Footer />
								</div>
							</div>
						</BrowserRouter>
					</div>
				</Fragment>
			)}
		</div>
	);
};

function Login() {
	const { login } = useStateContext();

	return (
		<div>
			<h2>Login</h2>
			<button style={{ backgroundColor: 'black', color: 'white' }} onClick={login}>
				Login
			</button>
		</div>
	);
}
// <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
// 									<TooltipComponent content="Settings" position="Top">
// 										<button
// 											type="button"
// 											onClick={() => setThemeSettings(true)}
// 											style={{ background: currentColor, borderRadius: '50%' }}
// 											className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray">
// 											<FiSettings />
// 										</button>
// 									</TooltipComponent>
// 								</div>

export default App;
