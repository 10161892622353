import React, { useContext, useEffect, useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { IoIosMore } from 'react-icons/io';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import axios from "axios";
// import geo from '.geo'

import { Stacked, Pie, Button, LineChart, SparkLine } from '../components';
import {
	earningData,
	medicalproBranding,
	recentTransactions,
	weeklyStats,
	dropdownData,
	SparklineAreaData,
	ecomPieChartData
} from '../assets/dummy';
import StateContext, { useStateContext } from '../contexts/ContextProvider';
import { CChart } from '@coreui/react-chartjs';
import { CChartBar } from '@coreui/react-chartjs';
import product9 from '../assets/product9.jpg';

const DropDown = ({ currentMode }) => (
	<div className="w-28 border-1 border-color px-2 py-1 rounded-md">
		<DropDownListComponent
			id="time"
			fields={{ text: 'Time', value: 'Id' }}
			style={{ border: 'none', color: currentMode === 'Dark' && 'white' }}
			value="1"
			dataSource={dropdownData}
			popupHeight="220px"
			popupWidth="120px"
		/>
	</div>
);

const Merchant = () => {
	const { currentColor, currentMode,distributor,setDistributor, users, setUsers, orderData, setOrderData, profileData, setProfileData } = useContext(StateContext);
	const context = useStateContext();
	const getToken = sessionStorage.getItem("token")
	const [tData, setTDatas] = useState([])
	const [totalEmp, setTotalEmp] = useState("")
	const [filterDate, setFilterDate] = useState("");
	const [graphData, setGraphData] = useState([]);

  const handleFilterChange = (event) => {
    setFilterDate(event.target.value);
  };

  const fetchGraphData = async () => {
    try {
      const token =  sessionStorage.getItem("token")

      const response = await axios.post('https://api.orbitmart.co.in/api/v1/order/graph',
        {
          filter: "year",
          filterDate: filterDate || "2024-01",
        },
        {
          headers: {
            "x-token": token,
            "Content-Type": "application/json",
          },
        }
      );
	  setGraphData(response.data.data.month)
      console.log("Graph Data:", response.data.data); // Process the response as needed
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };
  console.log(graphData,"adsfsdf")
  useEffect(() => fetchGraphData(),[filterDate])

	async function fetchProfile() {
		let token =   sessionStorage.getItem("token")
		const headers = {
		  "x-token": token,
		};
		const getProfileAPI = "https://api.orbitmart.co.in/api/v1/auth/admin/get-profile";
		await axios
		  .get(getProfileAPI, { headers })
		  .then((res) => {
			console.log("Profile", res.data.data);
			sessionStorage.setItem("adminData", JSON.stringify(res.data.data));	
			// setProfileData(res.data.data);
		  })
		  .catch((err) => {
			console.log(err.message);
		  });
	  }


	const { loginData } = useContext(StateContext);

	const adminData = sessionStorage.getItem('adminData')
	setProfileData(adminData);

	function fetchOrders() {
		axios
	
		  .get(`https://api.orbitmart.co.in/api/v1/order`)
		  .then((res) => {
			setOrderData(res.data.data)
		  })
		  .catch((err) => {
			console.log("orderdata error", err.message);
		  });
	  }
	  const fetchDistributorData = async () => {
		const header = {
          "x-token": getToken
		}
		try {
			// const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/allDistributor');
			const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/admin/allDistributor', {
				headers: header
			});
			setDistributor(response.data.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const fetchMerchnatData = async() => {
		try {
			const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/allMerchant');
			const data = response.data.data;
			console.log(data, "data")
			setTDatas(data);

		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}
	const fetchEmpData = async() => {
		try {
			const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/allEmployee');
			const data = response.data.data;
			console.log(data, "data")
			setTotalEmp(data);

		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	// useEffect(async () => {
	// 	await axios
	// 	  .get(`https://api.orbitmart.co.in/api/v1/auth/allUser`)
	// 	  .then((res) => setUsers(res.data.data));
	// 	  fetchOrders();
	// 	//   fetchDistributorData();
	// 	//   fetchMerchnatData();
	// 	//   fetchEmpData()
	// 	  fetchProfile()
	//   }, []);

   const [allCountDATA_, setAllCountDATA] = useState('')
	const handleAllCountData = async() => {
		console.log('Handle', getToken)
		const header = {
			"x-token": getToken
		  };
		try {
            const response = await axios.get(`https://api.orbitmart.co.in/api/v1/auth/admin/count`, {headers: header});
			console.log(response.data.data, "all count data")  // Process the response as needed  // admin/count  // auth/admin/count  // auth/count  // auth/allUser/count  // auth/allDistributor/count  // auth/allMerchant/count  // auth/allEmployee/count  // auth/admin/count  // auth/count  // auth/allUser/count  // auth/allDistribut
			setAllCountDATA(response.data.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
	} 

	useEffect(() => {
        handleAllCountData();
    }, []);


	// console.log('context', new Date().getMonth());
	// useEffect(() => {}, []);

	return (
		<div className="mt-12">
			<div className="d-flex flex-wrap lg:flex-nowrap justify-between ">
				<div style={{ display: 'flex', justifyContent: "space-around", alignItems: 'center', width: '100%' }}>
					<div style={{margin: '0 2rem'}}>
						<div>
							<div>
								<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-3 my-3 bg-no-repeat bg-cover bg-center" style={{width: "20rem",boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}}>
									<div className="flex justify-between items-center">
										<div>
											<p className="font-bold text-gray-400">TOTAL </p>
											<p className="font-bold text-gray-400">customer </p>
										</div>
											<p className="text-2xl">{allCountDATA_ ? allCountDATA_?.user : 0}</p>
									</div>

									{/* <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
            />
          </div> */}
								</div>

								<div>
								<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-3 my-3 bg-no-repeat bg-cover bg-center" style={{width: "20rem",boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}}>
									<div className="flex justify-between items-center">
										<div>
											<p className="font-bold text-gray-400">TOTAL </p>
											<p className="font-bold text-gray-400">Distributor </p>
										</div>
											<p className="text-2xl">{allCountDATA_ ? allCountDATA_?.distributor : 0}</p>
									</div>

									{/* <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
            />
          </div> */}
								</div>

								<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-3 my-3 bg-no-repeat bg-cover bg-center" style={{width: "20rem",boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}}>

									<div className="flex justify-between items-center">
										<div>
											<p className="font-bold text-gray-400">TOTAL</p>
											<p className="font-bold text-gray-400">Merchant</p>
										</div>
											<p className="text-2xl">{allCountDATA_ ? allCountDATA_?.merchant : 0}</p>
									</div>

									{/* <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
            />
          </div> */}
								</div>
								<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-3 my-3 bg-no-repeat bg-cover bg-center" style={{width: "20rem",boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}}>

									<div className="flex justify-between items-center">
										<div>
											<p className="font-bold text-gray-400">TOTAL</p>
											<p className="font-bold text-gray-400">Employees</p>
										</div>
											<p className="text-2xl">{allCountDATA_ ? allCountDATA_?.employee : 0}</p>
									</div>

									{/* <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
            />
          </div> */}
								</div>
							</div>

								<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-3 my-3 bg-no-repeat bg-cover bg-center" style={{width: "20rem",boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}}>

									<div className="flex justify-between items-center">
										<div>
											<p className="font-bold text-gray-400">TOTAL</p>
											<p className="font-bold text-gray-400">Order</p>
										</div>
											<p className="text-2xl">{allCountDATA_ ? allCountDATA_?.order : 0}</p>
									</div>

									{/* <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
            />
          </div> */}
								</div>
							</div>

							<div>
							<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-3 my-3 bg-no-repeat bg-cover bg-center" style={{width: "20rem",boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}}>
									<div className="flex justify-between items-center">
										<div>
											<p className="font-bold text-gray-400">TOTAL</p>
											<p className="font-bold text-gray-400">product category</p>
										</div>
											<p className="text-2xl">{allCountDATA_ ? allCountDATA_?.productCategory : 0}</p>
										{/* <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
            >
              <BsCurrencyDollar />
            </button> */}
									</div>

									{/* <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
            />
          </div> */}
								</div>

								<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-3 my-3 bg-no-repeat bg-cover bg-center" style={{width: "20rem",boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"}}>
									<div className="flex justify-between items-center">
										<div>
											<p className="font-bold text-gray-400">TOTAL</p>
											<p className="font-bold text-gray-400">product brand</p>
										</div>
											<p className="text-2xl">{allCountDATA_ ? allCountDATA_?.brandName : 0}</p>
										{/* <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
            >
              <BsCurrencyDollar />
            </button> */}
									</div>

									{/* <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
            />
          </div> */}
								</div>
							</div>
						</div>
					</div>
					<div>
						<div>
							<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-94 rounded-xl w-full lg:w-80 p-4 pt-5 m-1   bg-no-repeat bg-cover bg-center ">
								<div>
									<h2>Sellers</h2>
								</div>
								<CChart
									type="doughnut"
									data={{
										labels: [
											'Distributors',
											'orders',
											'Merchants',
											'Employees',
										],
										datasets: [
											{
												backgroundColor: [ '#41B883', '#E46651', '#00D8FF','#FFA62F' ],
												data: [ allCountDATA_ ? allCountDATA_?.distributor : 0,
													allCountDATA_ ? allCountDATA_?.order : 0,
													allCountDATA_ ? allCountDATA_?.merchant : 0,
													allCountDATA_ ? allCountDATA_?.employee : 0,
													 ]
											}
										]
									}}
								/>
							</div>

							{/* <div class="card" style={{ width: "400px", height: "1000px"  }}> */}
							<div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-94 rounded-xl w-full lg:w-80 px-4 m-1   bg-no-repeat bg-cover bg-center">
								<div>
									<h1>Products</h1>
								</div>
								<CChart
									type="doughnut"
									data={{
										labels: [
											'Total Product Brand',
											'Total Product Category',
										],
										datasets: [
											{
												backgroundColor: [ '#41B883', '#E46651', '#00D8FF' ],
												data: [ 
													    allCountDATA_ ? allCountDATA_?.brandName : 0,
                                                    allCountDATA_ ? allCountDATA_?.productCategory : 0,
                                                     ]
											}
										]
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white dark:text-black-200 dark:bg-secondary-dark-bg h-90 rounded-xl w-full p-8 pt-9 m-6  bg-no-repeat bg-cover bg-center"> {/*lg:w-800*/}
					{/* <div style={{display:"flex"}}>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">Oriz</option>
  <option value="2">counter sell</option>
  <option value="3">personal app</option>
</select></div><br/>

<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">Cancel</option>
  <option value="2">Pending</option>
  <option value="3">Processing</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
</div> */}
					<div style={{ display: 'flex' }}>
						{/* <div class="relative z-0 p-2 w-full mb-6 group">
							<label>Order Type</label>
							<select
								class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								aria-label="Default select example">
								<option>All</option>
								<option value="MALE">Oriz</option>
								<option value="FEMALE">counter sell</option>
								<option value="FEMALE">Personal app</option>
							</select>
						</div> */}

						{/* <div class="relative z-0 p-2 w-full mb-6 group">
							<label>Order status</label>
							<select
								class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								aria-label="Default select example">
								<option>All</option>
								<option value="1">Cancel</option>
								<option value="2">Pending</option>
								<option value="3">Processing</option>
							</select>
						</div> */}

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Payment status </label>
							<select
								class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								aria-label="Default select example">
								{/* <option>All</option> */}
								{/* <option value="pending">pending</option> */}
								<option value="Complete">Complete</option>
								{/* <option value="failed">failed</option> */}
							</select>
						</div>

						<div class="relative z-0 p-2 w-full mb-6 group">
							<label>Month/Year</label>
							<input
								type="month"
								class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
								placeholder="Enter Month/Year"
								value={filterDate}
          onChange={handleFilterChange}
							/>
						</div>
					</div>
					<CChartBar
						data={{
							labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November', 'December'],
							datasets: [
								{
									label: 'Restaurant Order Statistics',
									backgroundColor: '#f87979',
									data: [graphData?.January || 0,
										graphData?.February || 0,
										graphData?.March || 0,
										graphData?.April || 0,
										graphData?.May || 0,
										graphData?.June || 0,
										graphData?.July || 0,
										graphData?.August || 0,
										graphData?.September || 0,
										graphData?.October || 0,
										graphData?.November || 0,
										graphData?.December || 0,]
								}
							]
						}}
						labels="months"
					/>
				</div>
			</div>
		</div>
	);
};

export default Merchant;
